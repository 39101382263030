import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { ErrorStringConstants } from "common";
import React from "react";

import styles from "./NoBankAccountsMessage.module.scss";

/**
 * Custom no rows overlay component when there are no rows loaded on elections grid
 */
export const NoBankAccountsMessage = () => {
  return (
    <div className={styles.noAccounts}>
      <span className={styles.message}>
        <ErrorOutlineOutlinedIcon className={styles.icon} />
        {ErrorStringConstants.NO_BANK_ACCOUNTS}
      </span>
    </div>
  );
};
