import EditRoundedIcon from "@mui/icons-material/EditRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { IconButton } from "@mui/material";
import {
  AccountNumberType,
  AccountType,
  AddBankAccountState,
  AddOrEditBankAccountDialog,
  BankAccountAssignment,
  BankIdType,
  GPBankAccount,
  GPPendingBankAccount,
  IBankAccount,
  IBaseStore,
  isSomething,
  Optional,
  UserAddedBankAccount,
  ViewBankAccountDialog,
  ViewOrEditBankAccount,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./ViewOrEditBankAccountCellRenderer.module.scss";

export interface IViewOrEditBankAccountCellRendererProps {
  account: ViewOrEditBankAccount;
  investmentVehicleIds: number[];
}

export const ViewOrEditBankAccountCellRenderer = (
  props: IViewOrEditBankAccountCellRendererProps
) => {
  const { account, investmentVehicleIds } = props;

  const [openView, setOpenView] = useState<boolean>(false);
  const [openAddEdit, setOpenAddEdit] = useState<boolean>(false);

  const { serverData, userAddedAccounts } = useSelector(
    (state: IBaseStore) => state.bankAccounts
  );

  const convertToAddAccountState = useCallback(
    (
      main: IBankAccount,
      intermediary: Optional<IBankAccount>,
      assignments: BankAccountAssignment[]
    ): AddBankAccountState => {
      return {
        ...main,
        confirmAccountId: main.accountNumber,
        hasIntermediaryAccount: isSomething(intermediary),
        intermediaryAccount: isSomething(intermediary)
          ? {
              ...intermediary.value,
              confirmAccountId: intermediary.value.accountNumber,
            }
          : {
              bankAccountUniqueId: "",
              accountHolderName: "",
              accountType: AccountType.INTERMEDIARY,
              accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
              accountNumber: "",
              bankIdType: BankIdType.ABA_ROUTING_NUMBER,
              bankId: "",
              confirmAccountId: "",
              country: "",
              currency: "",
              bankName: "",
            },
        autoAssign: {
          [BankAccountAssignment.CONTRIBUTION]: {
            isChecked: assignments.includes(BankAccountAssignment.CONTRIBUTION),
            isEligible: assignments.includes(
              BankAccountAssignment.CONTRIBUTION
            ),
          },
          [BankAccountAssignment.DISTRIBUTION]: {
            isChecked: assignments.includes(BankAccountAssignment.DISTRIBUTION),
            isEligible: assignments.includes(
              BankAccountAssignment.DISTRIBUTION
            ),
          },
          [BankAccountAssignment._]: {
            isChecked: false,
            isEligible: false,
          },
        },
        canAutoAssignPurposes: investmentVehicleIds.length === 1,
      };
    },
    [investmentVehicleIds]
  );

  const bankAccount: AddBankAccountState | undefined = useMemo(() => {
    if (account.isUnderReview) {
      const pendingAccount: GPPendingBankAccount | undefined =
        serverData.pendingBankAccounts.find(
          (acc: GPPendingBankAccount) =>
            acc.main.bankAccountUniqueId === account.bankAccountUniqueId
        );
      if (pendingAccount) {
        return convertToAddAccountState(
          pendingAccount.main,
          pendingAccount.intermediary,
          pendingAccount.assignments
        );
      }
    } else {
      const approvedAccount: GPBankAccount | undefined =
        serverData.bankAccounts.find(
          (acc: GPBankAccount) =>
            acc.bankAccount.main.bankAccountUniqueId ===
            account.bankAccountUniqueId
        );
      if (approvedAccount) {
        return convertToAddAccountState(
          approvedAccount.bankAccount.main,
          approvedAccount.bankAccount.intermediary,
          approvedAccount.bankAccount.assignments
        );
      }
      const userAddedAccount: UserAddedBankAccount | undefined =
        userAddedAccounts.find(
          (acc: UserAddedBankAccount) =>
            acc.main.bankAccountUniqueId === account.bankAccountUniqueId
        );
      if (userAddedAccount) {
        return convertToAddAccountState(
          userAddedAccount.main,
          userAddedAccount.intermediary,
          userAddedAccount.assignedTo
        );
      }
    }
    return undefined;
  }, [account, serverData, convertToAddAccountState, userAddedAccounts]);

  if (bankAccount === undefined) {
    return <></>;
  }

  return account.isUnderReview ? (
    <>
      <IconButton className={styles.icons} onClick={() => setOpenView(true)}>
        <VisibilityRoundedIcon />
      </IconButton>
      <ViewBankAccountDialog
        open={openView}
        setOpen={setOpenView}
        showAutoAssignSection={investmentVehicleIds.length === 1}
        assignmentType={BankAccountAssignment._}
        bankAccountState={bankAccount}
      />
    </>
  ) : (
    <>
      <IconButton className={styles.icons} onClick={() => setOpenAddEdit(true)}>
        <EditRoundedIcon />
      </IconButton>
      <AddOrEditBankAccountDialog
        open={openAddEdit}
        setOpen={setOpenAddEdit}
        showAutoAssignSection={investmentVehicleIds.length === 1}
        assignmentType={BankAccountAssignment._}
        investmentVehicleIds={investmentVehicleIds}
        existingState={bankAccount}
      />
    </>
  );
};
