import { Stack, Typography } from "@mui/material";
import { GPInvestmentVehicle, IBaseStore, SectionHeader } from "common";
import { AccountAssignmentGrid } from "common/src/features/BankAccounts/AccountAssignmentGrid/AccountAssignmentGrid";
import { renderTextWithAppSupportLink } from "common/src/utils/specialRendersUtils";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import styles from "./AssignBankAccountsCard.module.scss";

export interface AssignBankAccountsCardProps {
  retryFunction: () => void;
}

export const AssignBankAccountsCard = ({
  retryFunction,
}: AssignBankAccountsCardProps) => {
  const bankAccountsForClient = useSelector(
    (state: IBaseStore) => state.bankAccounts.serverData
  );

  const investmentVehicleIds = useMemo(() => {
    return bankAccountsForClient.investmentVehicles.map(
      (investmentVehicle: GPInvestmentVehicle) => investmentVehicle.axiomId
    );
  }, [bankAccountsForClient]);

  return (
    <Stack className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <Typography variant="h1">
          {SectionHeader.ASSIGN_BANK_ACCOUNTS}
        </Typography>
        <Typography variant="body1">
          {renderTextWithAppSupportLink(
            SectionHeader.ASSIGN_BANK_ACCOUNTS_DESCRIPTION
          )}
        </Typography>
      </div>
      <AccountAssignmentGrid
        purposesWithError={[]}
        retryFunction={retryFunction}
        investmentVehicleIds={investmentVehicleIds}
      />
    </Stack>
  );
};
