import React from "react";

import styles from "./ClientLabel.module.scss";

type ClientLabelProps = {
  label: string;
};

const ClientLabel = ({ label }: ClientLabelProps) => {
  return <span className={styles.InvestmentEntityClient}>{label}</span>;
};

export default ClientLabel;
