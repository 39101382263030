import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Link } from "@mui/material";
import { ErrorStringConstants, StringConstants } from "common";
import React from "react";

import styles from "./NoElectionsMessage.module.scss";

/**
 * Custom no rows overlay component when there are no rows loaded on elections grid
 */
export const NoElectionsMessage = () => {
  const noDocsErrorMsg = (
    <div className={styles.message}>
      <ErrorOutlineOutlinedIcon className={styles.icon} />
      {ErrorStringConstants.NO_ELECTIONS} {ErrorStringConstants.PLEASE_CONTACT}
      <Link
        className={styles.link}
        href={StringConstants.MAIL_TO_BXWEALTH_SUPPORT_EMAIL}
      >
        {StringConstants.BXWEALTH_SUPPORT_EMAIL}
      </Link>
      {ErrorStringConstants.WITH_ANY_QUESTIONS}
    </div>
  );

  return (
    <div className={styles.noElections}>
      <span className={styles.message}>{noDocsErrorMsg}</span>
    </div>
  );
};
