import { PathFragment, Review } from "common";
import React from "react";

import { useActiveElection } from "../Hooks/useActiveElectionHook";
import { useElectionQueryParams } from "../Hooks/useElectionQueryParamsHook";

export const ElectionReviewWrapper = () => {
  const { electionRoundId, investmentVehicleId } = useElectionQueryParams();
  useActiveElection();

  return (
    <Review
      incompletePath={`/${PathFragment.ELECTIONS}/${investmentVehicleId}/${electionRoundId}`}
      notFoundPath={PathFragment.ELECTIONS}
      electionListPath={`/${PathFragment.ELECTIONS}`}
    />
  );
};
