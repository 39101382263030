import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { isInProgress, LoadingIndicator, Markdown } from "common";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";
import { UserAgreementsConstants } from "./constants";
import styles from "./UserAgreementCard.module.scss";

interface AgreementCardProps {
  agreement: {
    id: number;
    name: string;
    description: string;
    hasAgreed: boolean | null;
  };
  currentAgreementIndex: number;
  totalAgreements: number;
  onAgreeClick: (agreementId: number) => void;
  buttonText: string;
}
export const AgreementCard: React.FC<AgreementCardProps> = ({
  agreement,
  currentAgreementIndex,
  totalAgreements,
  onAgreeClick,
  buttonText,
}) => {
  const cardContentRef = useRef<HTMLDivElement | null>(null);

  const { userAgreementsLoadStatus } = useSelector(
    (state: InvestorUIStore) => state.userAgreementData
  );

  useEffect(() => {
    if (cardContentRef.current) {
      cardContentRef.current.scrollTop = 0;
    }
  }, [agreement]);

  if (isInProgress(userAgreementsLoadStatus)) {
    return (
      <div className={styles.userAgreementCard}>
        <Card className={styles.agreementCard}>
          <div className={styles.loadingIndicator}>
            <LoadingIndicator />
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.userAgreementCard}>
      <Card className={styles.agreementCard}>
        <div className={styles.headerAndContent}>
          <CardHeader
            title={UserAgreementsConstants.TITLE}
            className={styles.header}
            titleTypographyProps={{
              className: styles.title,
            }}
            subheader={
              <Typography className={styles.subHeader}>
                <p className={styles.paragraph}>
                  <b>{agreement.name}</b>
                  <br />
                  <b>
                    ({UserAgreementsConstants.AGREEMENT} {currentAgreementIndex}{" "}
                    {UserAgreementsConstants.OF} {totalAgreements})
                  </b>
                </p>
                {agreement.hasAgreed === true && (
                  <span>
                    <CheckCircleIcon className={styles.agreementStatusIcon} />
                  </span>
                )}
              </Typography>
            }
          ></CardHeader>
          <CardContent
            className={styles.cardContentWrapper}
            ref={cardContentRef}
          >
            <div className={styles.cardContent}>
              <Markdown text={agreement.description} />
            </div>
          </CardContent>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.cardFooter}>
          <Button onClick={() => onAgreeClick(agreement.id)}>
            {buttonText}
          </Button>
        </div>
      </Card>
    </div>
  );
};
