import { OverviewStage } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectCanReadBankAccountsForActiveClient } from "../../../../redux/selectors/entitlementSelectors";

export const OverviewStageWrapper = () => {
  const canReadBankAccounts = useSelector(
    selectCanReadBankAccountsForActiveClient
  );

  return <OverviewStage canReadBankAccounts={canReadBankAccounts} />;
};
