import {
  IBaseStore,
  InvestmentEntityDropdown,
  isSomething,
  selectInternalInvestmentData,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";
import ClientLabel from "../ClientDropdown/ClientLabel";

interface InvestmentEntityDropdownWrapperProps {
  clientsOnly?: boolean;
}

const InvestmentEntityDropdownWrapper = (
  props: InvestmentEntityDropdownWrapperProps
) => {
  const { viewInternalInvestmentDataPermittedEntities } = useSelector(
    (store: InvestorUIStore) => store.entitlements
  );
  const { filteredBy } = useSelector((state: IBaseStore) =>
    selectInternalInvestmentData(state)
  );

  const activeClient = useMemo(() => {
    if (
      !isSomething(filteredBy) ||
      !isSomething(viewInternalInvestmentDataPermittedEntities)
    )
      return null;
    const activeClientId = filteredBy.value.clientId;

    return viewInternalInvestmentDataPermittedEntities.value.clients.find(
      (c) => c.id === activeClientId
    );
  }, [filteredBy, viewInternalInvestmentDataPermittedEntities]);

  // Logic to determine if the entity selector should be shown
  const showEntitySelector = useMemo(() => {
    if (activeClient && activeClient.investmentVehicles.length >= 2)
      return true;
  }, [activeClient]);

  return showEntitySelector ? (
    <InvestmentEntityDropdown
      viewInternalInvestmentDataPermittedEntities={
        viewInternalInvestmentDataPermittedEntities
      }
      clientsOnly={props.clientsOnly}
    />
  ) : (
    <ClientLabel label={activeClient?.name ?? ""} />
  );
};

export default InvestmentEntityDropdownWrapper;
