import {
  IBankAccountPermittedClient,
  IBaseStore,
  isSomething,
  reqBankAccountsForClient,
  setActiveBankAccountClient,
  some,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ClientDropdown } from "../../../common/components/ClientDropdown/ClientDropdown";
import ClientLabel from "../../../common/components/ClientDropdown/ClientLabel";
import { InvestorUIStore } from "../../../redux/store";

export const BankAccountsClientDropdown = () => {
  const dispatch = useDispatch();

  const { activeBankAccountsClient } = useSelector(
    (state: IBaseStore) => state.bankAccounts
  );

  const {
    bankAccountsEntitlements: { permittedBankAccountClients },
  } = useSelector((store: InvestorUIStore) => store.entitlements);

  const onClientChange = (client: IBankAccountPermittedClient) => {
    dispatch(setActiveBankAccountClient(some(client)));
    dispatch(reqBankAccountsForClient(client));
  };

  return permittedBankAccountClients.length >= 2 ? (
    <ClientDropdown
      activeClient={activeBankAccountsClient}
      permittedClients={permittedBankAccountClients}
      onChange={onClientChange}
    />
  ) : (
    <ClientLabel
      label={
        isSomething(activeBankAccountsClient)
          ? activeBankAccountsClient.value.clientName
          : ""
      }
    />
  );
};
