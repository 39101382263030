import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Link } from "@mui/material";
import { ErrorStringConstants, StringConstants } from "common";
import React from "react";

import styles from "./NoDocumentsAvailableError.module.scss";

/**
 * Custom no rows overlay component when there are no rows loaded on the grid.
 * There can be no rows for the following reasons:
 * 1. User doesn't have entitlements to view documents
 * 2. User has no documents
 * 3. No documents match the filter(s) that user has selected
 * @returns the no documents available component
 */
export const NoDocumentsAvailableError = () => {
  const noDocsErrorMsg = (
    <div className={styles.message}>
      <ErrorOutlineOutlinedIcon className={styles.icon} />
      {ErrorStringConstants.NO_DOCUMENTS} {ErrorStringConstants.PLEASE_CONTACT}{" "}
      <Link
        className={styles.link}
        href={StringConstants.MAIL_TO_BXWEALTH_SUPPORT_EMAIL}
      >
        {StringConstants.BXWEALTH_SUPPORT_EMAIL}{" "}
      </Link>
      {ErrorStringConstants.WITH_ANY_QUESTIONS}
    </div>
  );

  // if no filters then just show no docs error, but if filters are selected
  // return a different error advising user to change/remove filter(s)
  return (
    <div className={styles.noDocuments}>
      <span className={styles.message}>{noDocsErrorMsg}</span>
    </div>
  );
};
