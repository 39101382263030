import { IUserAgreement, IWriteUserAgreement } from "../types/dataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleResponse } from "./utils";

export type IUserAgreementsSource = Json<IUserAgreement>[];

export const getUserAgreements = async (): Promise<
  Maybe<IUserAgreementsSource>
> => {
  const user = getUser();
  const url = `${EnvironmentResolver.ENV.REACT_APP_USER_AGREEMENTS_URL_BASE}/my/agreements`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};

export const saveUserAgreement = async (
  payload: IWriteUserAgreement
): Promise<void> => {
  const user = getUser();
  const url = `${EnvironmentResolver.ENV.REACT_APP_USER_AGREEMENTS_URL_BASE}/my/agreements`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify(payload),
  };

  const response = await fetch(url, request);
  await handleResponse(response);
};
