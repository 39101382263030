import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { DataLoadStatus } from "../../constants/enums";
import {
  getUserAgreements,
  IUserAgreementsSource,
  saveUserAgreement,
} from "../../services/userAgreementsService";
import { IUserAgreement, IWriteUserAgreement } from "../../types/dataTypes";
import { Json, Maybe } from "../../types/typeUtils";
import {
  errUserAgreements,
  recvUserAgreements,
} from "../actions/userAgreementsActions";

const parseJsonToUserAgreements = (
  source: Json<IUserAgreement>[]
): IUserAgreement[] => {
  return [...source].sort((a, b) => a.id - b.id) as IUserAgreement[];
};

export function* fetchUserAgreements() {
  try {
    const userAgreementsSource: Maybe<IUserAgreementsSource> = yield call(
      getUserAgreements
    );
    if (userAgreementsSource !== undefined) {
      yield put(
        recvUserAgreements(parseJsonToUserAgreements(userAgreementsSource))
      );
    }
  } catch {
    yield put(errUserAgreements(DataLoadStatus.UNSUCCESSFUL));
  }
}

export function* writeUserAgreement(
  action: PayloadAction<IWriteUserAgreement>
) {
  try {
    yield call(saveUserAgreement, action.payload);
    const userAgreementsSource: Maybe<IUserAgreementsSource> = yield call(
      getUserAgreements
    );

    if (userAgreementsSource !== undefined) {
      yield put(
        recvUserAgreements(parseJsonToUserAgreements(userAgreementsSource))
      );
    }
  } catch {
    yield put(errUserAgreements(DataLoadStatus.UNSUCCESSFUL));
  }
}
