import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useElectionQueryParams = () => {
  const { investmentVehicleId: investmentVehicleIdParam, electionRoundId } =
    useParams();

  const investmentVehicleId = useMemo(() => {
    if (
      investmentVehicleIdParam &&
      !isNaN(parseInt(investmentVehicleIdParam))
    ) {
      return parseInt(investmentVehicleIdParam);
    }
  }, [investmentVehicleIdParam]);

  return { electionRoundId, investmentVehicleId };
};
