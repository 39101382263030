import { SelectChangeEvent } from "@mui/material";
import {
  BaseEntityDropdown,
  ClientSelectLabels,
  IBankAccountPermittedClient,
  IClientDropdownItem,
  IDropdownItemData,
  IPermittedClient,
  isSomething,
  Optional,
} from "common";
import React, { useMemo } from "react";

import styles from "./ClientDropdown.module.scss";

type ClientDropDownProps<T> = {
  activeClient: Optional<T>;
  permittedClients: T[];
  onChange: (client: T) => void;
};
export const ClientDropdown = <
  T extends IPermittedClient | IBankAccountPermittedClient
>(
  props: ClientDropDownProps<T>
) => {
  const { activeClient, permittedClients, onChange } = props;

  const clientDropdownItems: IClientDropdownItem[] = useMemo(() => {
    return permittedClients.map((permittedClient: T) => {
      return {
        id: permittedClient.clientId,
        name: permittedClient.clientName,
      };
    });
  }, [permittedClients]);

  const onClientChange = (event: SelectChangeEvent) => {
    const client = parseInt(event.target.value as string, 10);
    const selectedClient: T | undefined = permittedClients.find(
      (x) => x.clientId === client
    );
    if (
      selectedClient !== undefined &&
      isSomething(activeClient) &&
      selectedClient !== activeClient.value
    ) {
      onChange(selectedClient);
    }
  };

  const getMenuItemTextClass = (
    clientDropdownItem: IClientDropdownItem
  ): string => {
    if (
      isSomething(activeClient) &&
      clientDropdownItem.id === activeClient.value.clientId
    ) {
      return styles.selectedClientText;
    }
    return styles.clientText;
  };

  const getActiveItemLabel = (id: string): string => {
    const label =
      permittedClients.find((x) => x.clientId.toString() === id)?.clientName ||
      "";
    return label;
  };

  const getMenuItemData = (item: IClientDropdownItem): IDropdownItemData => {
    const label = item.name;
    const classes = getMenuItemTextClass(item);
    return { label, classes };
  };

  return isSomething(activeClient) ? (
    <BaseEntityDropdown
      items={clientDropdownItems}
      getActiveItemLabel={getActiveItemLabel}
      getMenuItemData={getMenuItemData}
      onSelectionChange={onClientChange}
      selectedValue={activeClient.value.clientId}
      subheaderLabel={ClientSelectLabels.DEFAULT_TEXT}
    />
  ) : (
    <></>
  );
};
