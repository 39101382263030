import {
  AddBankAccountState,
  BankAccountAssignment,
  BankAccountConstants,
  BankAccountDialogType,
} from "common";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import { BankAccountForm } from "./BankAccountForm/BankAccountForm";

export interface IViewBankAccountDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showAutoAssignSection: boolean;
  assignmentType: BankAccountAssignment;
  bankAccountState: AddBankAccountState;
}

export const ViewBankAccountDialog = (props: IViewBankAccountDialogProps) => {
  const {
    open,
    setOpen,
    showAutoAssignSection,
    assignmentType,
    bankAccountState,
  } = props;

  const addBankAccountForm = useForm<AddBankAccountState>({
    mode: "onSubmit",
    defaultValues: bankAccountState,
  });

  // needed to ensure default values are set to country and currency properly
  useEffect(() => {
    addBankAccountForm.reset(bankAccountState);
  }, [addBankAccountForm, bankAccountState]);

  // If there are any changes, confirmation state triggers and the modal
  // requires the user to confirm its closing. Otherwise it closes immedately.
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <ElectionDialog
      title={BankAccountConstants.VIEW_ACCOUNT}
      content={
        <BankAccountForm
          addBankAccountForm={addBankAccountForm}
          assignmentType={assignmentType}
          isConfirmClose={false}
          showAutoAssign={showAutoAssignSection}
          purposeIneligibility={{
            [BankAccountAssignment.CONTRIBUTION]: [],
            [BankAccountAssignment.DISTRIBUTION]: [],
            [BankAccountAssignment._]: [],
          }}
          bankAccountDialogType={BankAccountDialogType.VIEW}
        />
      }
      open={open}
      handleClose={handleClose}
      handleNext={handleClose}
      cancelButtonLabel={"Close"}
      showNextButton={false}
    />
  );
};
