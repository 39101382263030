import { Box, Link, Typography } from "@mui/material";
import { EnvironmentResolver } from "common";
import React from "react";

import { FooterConstants } from "./constants";

interface LoginFooterProps {
  className?: string;
  textClassName?: string;
  dividerClassName?: string;
}

// Footer for login pages. It excludes the Terms of Use link that we only want
// to show logged in users who have already signed their user agreements.
export const LoginFooter: React.FC<LoginFooterProps> = ({
  className,
  dividerClassName,
  textClassName,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      className={className}
    >
      <Link
        className={textClassName}
        href={EnvironmentResolver.ENV.LOGIN_HELP_LINK_HREF}
      >
        {FooterConstants.HELP}
      </Link>
      <Typography className={dividerClassName}> | </Typography>
      <Link
        className={textClassName}
        href="https://www.blackstone.com/"
        target="_blank"
      >
        {FooterConstants.BLACKSTONE_HOME}
      </Link>
      <Typography className={dividerClassName}> | </Typography>
      <Link
        className={textClassName}
        href="https://www.blackstone.com/privacy#onlinePrivacyNotice"
        target="_blank"
      >
        {FooterConstants.PRIVACY_POLICY}
      </Link>
    </Box>
  );
};
