export const LoginConstants = {
  Error: {
    EMPTY_USERNAME: "Please enter your username or email to continue.",
  },
  FooterLinkText: {
    BLACKSTONE_HOME: "Blackstone Home",
    HELP: "Help",
    PRIVACY_POLICY: "Privacy Policy",
  },
  INPUT_PLACEHOLDER: "Username or email",
  NEXT_BUTTON_TEXT: "Next",
  REMEMBER_ME_LABEL: "Remember Me",
  TITLE: "Welcome to Blackstone",
  USERNAME_COOKIE: "username",
};
