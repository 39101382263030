import {
  combineReducers,
  configureStore,
  createSerializableStateInvariantMiddleware,
  isPlain,
  Reducer,
  Tuple,
} from "@reduxjs/toolkit";
import {
  authReducer,
  bankAccountsReducer,
  electionsReducer,
  equityDataReducer,
  fetchAvailableDocumentFilters,
  fetchBankAccountsCountries,
  fetchBankAccountsCurrencies,
  fetchBankAccountsForClient,
  fetchBankAccountsForIV,
  fetchDistributionsData,
  fetchDocuments,
  fetchDocumentsClients,
  fetchElectionConfiguration,
  fetchElectionIVConfiguration,
  fetchElectionsForClient,
  fetchElectionWorkflowState,
  fetchEquityData,
  fetchInternalInvestmentData,
  fetchInvestmentPortfolio,
  fetchStockData,
  fetchUserAgreements,
  fetchViewEntitlements,
  IBaseStore,
  internalInvestmentDataReducer,
  investorUIViewDataReducer,
  putUserAgreement,
  reqAllEntitlements,
  reqAvailableDocumentFilters,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  reqBankAccounts,
  reqBankAccountsForClient,
  reqDistributionsData,
  reqDocumentClients,
  reqDocuments,
  reqDocumentsForSearch,
  reqElectionIVConfiguration,
  reqElectionRoundConfiguration,
  reqElectionsForClient,
  reqElectionsInvestmentPortfolio,
  reqElectionWorkflowState,
  reqEquityData,
  reqInternalInvestmentData,
  reqPutElectionWorkflowState,
  reqStockData,
  reqUserAgreements,
  userAgreementsReducer,
  writeElectionWorkflowState,
  writeUserAgreement,
} from "common";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { takeLatest, takeLeading } from "redux-saga/effects";

import { investorUIDocumentReducer } from "./reducers/documentReducer";
import { investorUIEntitlementReducer } from "./reducers/entitlementReducer";

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield takeLeading(reqUserAgreements, fetchUserAgreements);
  yield takeLeading(putUserAgreement, writeUserAgreement);
  yield takeLeading(reqAllEntitlements, fetchViewEntitlements);
  yield takeLeading(reqInternalInvestmentData, fetchInternalInvestmentData);
  yield takeLeading(reqEquityData, fetchEquityData);
  yield takeLeading(reqStockData, fetchStockData);
  yield takeLeading(reqDistributionsData, fetchDistributionsData);
  yield takeLeading(reqDocumentClients, fetchDocumentsClients);
  yield takeLeading(reqAvailableDocumentFilters, fetchAvailableDocumentFilters);
  yield takeLeading(reqDocuments, fetchDocuments);
  // takeLatest works best with page-level search bar with its rapid text input changes
  yield takeLatest(reqDocumentsForSearch, fetchDocuments);
  yield takeLeading(reqElectionRoundConfiguration, fetchElectionConfiguration);
  yield takeLeading(reqElectionsForClient, fetchElectionsForClient);
  yield takeLeading(reqElectionIVConfiguration, fetchElectionIVConfiguration);
  yield takeLeading(reqElectionWorkflowState, fetchElectionWorkflowState);
  yield takeLeading(reqElectionsInvestmentPortfolio, fetchInvestmentPortfolio);
  yield takeLeading(reqPutElectionWorkflowState, writeElectionWorkflowState);
  yield takeLeading(reqBankAccounts, fetchBankAccountsForIV);
  yield takeLeading(reqBankAccountsForClient, fetchBankAccountsForClient);
  yield takeLeading(reqBankAccountCountries, fetchBankAccountsCountries);
  yield takeLeading(reqBankAccountCurrencies, fetchBankAccountsCurrencies);
}

const rootReducer = combineReducers({
  entitlements: investorUIEntitlementReducer,
  internalInvestmentData: internalInvestmentDataReducer,
  equityData: equityDataReducer,
  viewData: investorUIViewDataReducer,
  documentSettings: investorUIDocumentReducer,
  selectedElection: electionsReducer,
  bankAccounts: bankAccountsReducer,
  auth: authReducer,
  userAgreementData: userAgreementsReducer,
});

export type InvestorUIStore = ReturnType<typeof rootReducer> &
  Reducer<IBaseStore>;

const getCustomMiddleware = () => {
  // Allow dates even though they are not JSON serializable.
  // NOTE: This means we have to be vigilant! Don't let someone
  // secretly mutate the store with "date.setDate(5)" or similar!
  const customSerializableMiddleware =
    createSerializableStateInvariantMiddleware({
      isSerializable: (value: unknown) =>
        isPlain(value) || value instanceof Date,
    });

  if (process.env.REACT_APP_SHOW_CONSOLE_LOGGING === "true") {
    return new Tuple(sagaMiddleware, customSerializableMiddleware, logger);
  }

  return new Tuple(sagaMiddleware, customSerializableMiddleware);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      getCustomMiddleware()
    ),
});

sagaMiddleware.run(rootSaga);
