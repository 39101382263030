import { createReducer } from "@reduxjs/toolkit";
import {
  DataLoadStatus,
  errBankAccountsEntitlements,
  errDocumentClients,
  errDocumentsEntitlements,
  errElectionsEntitlements,
  errEquityEntitlements,
  errRestrictedEquityEntitlement,
  errStockEntitlements,
  errV2DashboardEquityCardEntitlement,
  isSomething,
  nothing,
  recvBankAccountsEntitlements,
  recvDocumentClients,
  recvDocumentsEntitlement,
  recvEquityEntitlement,
  recvInternalInvestmentDataPermittedEntities,
  recvPermittedElectionClients,
  recvRestrictedEquityEntitlement,
  recvStockEntitlement,
  recvV2DashboardEquityCardEntitlement,
  reqAllEntitlements,
  reqDocumentClients,
} from "common";

import { InvestorUIEntitlementStore } from "../../types/storetypes";

export const investorUIEntitlementReducer =
  createReducer<InvestorUIEntitlementStore>(
    {
      viewInternalInvestmentDataPermittedEntities: nothing,
      internalInvestmentEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
      viewEquityEntitlements: [],
      equityDataEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
      hasViewStockEntitlement: false,
      stockDataEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
      documentsEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
      hasViewDocumentsEntitlement: false,
      documentClients: nothing,
      documentClientsLoadStatus: DataLoadStatus.NOT_REQUESTED,
      electionsEntitlementLoadStatus: DataLoadStatus.NOT_REQUESTED,
      permittedElectionClients: [],
      bankAccountsEntitlements: {
        globalCapabilities: [],
        permittedBankAccountClients: [],
      },
      bankAccountsEntitlementsLoadStatus: DataLoadStatus.NOT_REQUESTED,
      hasViewRestrictedEquityEntitlement: false,
      hasViewV2DashboardEquityCardEntitlement: false,
    },
    (builder) => {
      builder.addCase(reqAllEntitlements, (state) => {
        state.internalInvestmentEntitlementLoadStatus = DataLoadStatus.LOADING;
        state.equityDataEntitlementLoadStatus = DataLoadStatus.LOADING;
        state.stockDataEntitlementLoadStatus = DataLoadStatus.LOADING;
        state.documentsEntitlementLoadStatus = DataLoadStatus.LOADING;
        state.electionsEntitlementLoadStatus = DataLoadStatus.LOADING;
        state.bankAccountsEntitlementsLoadStatus = DataLoadStatus.LOADING;
      });
      builder.addCase(
        recvInternalInvestmentDataPermittedEntities,
        (state, action) => {
          if (isSomething(action.payload)) {
            state.viewInternalInvestmentDataPermittedEntities = action.payload;
            state.internalInvestmentEntitlementLoadStatus =
              DataLoadStatus.SUCCESSFUL;
          } else {
            state.internalInvestmentEntitlementLoadStatus =
              DataLoadStatus.EMPTY_RESPONSE;
          }
        }
      );
      builder.addCase(recvEquityEntitlement, (state, action) => {
        if (action.payload.length > 0) {
          state.equityDataEntitlementLoadStatus = DataLoadStatus.SUCCESSFUL;
        } else {
          state.equityDataEntitlementLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
        }
        state.viewEquityEntitlements = action.payload;
      });
      builder.addCase(recvStockEntitlement, (state, action) => {
        state.hasViewStockEntitlement = action.payload;
        if (action.payload) {
          state.stockDataEntitlementLoadStatus = DataLoadStatus.SUCCESSFUL;
        } else {
          state.stockDataEntitlementLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
        }
      });
      builder.addCase(recvDocumentsEntitlement, (state, action) => {
        if (isSomething(action.payload)) {
          state.documentsEntitlementLoadStatus = DataLoadStatus.SUCCESSFUL;
          state.hasViewDocumentsEntitlement = action.payload.value;
        } else {
          state.documentsEntitlementLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
          state.hasViewDocumentsEntitlement = false;
        }
      });
      builder.addCase(errEquityEntitlements, (state, action) => {
        state.equityDataEntitlementLoadStatus = action.payload;
      });
      builder.addCase(errStockEntitlements, (state, action) => {
        state.stockDataEntitlementLoadStatus = action.payload;
      });
      builder.addCase(errDocumentsEntitlements, (state, action) => {
        state.documentsEntitlementLoadStatus = action.payload;
      });
      builder.addCase(reqDocumentClients, (state) => {
        state.documentClientsLoadStatus = DataLoadStatus.LOADING;
      });
      builder.addCase(recvDocumentClients, (state, action) => {
        if (isSomething(action.payload)) {
          state.documentClientsLoadStatus = DataLoadStatus.SUCCESSFUL;
        } else {
          state.documentClientsLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
        }
        state.documentClients = action.payload;
      });
      builder.addCase(errDocumentClients, (state, action) => {
        state.documentClientsLoadStatus = action.payload;
      });
      builder.addCase(recvPermittedElectionClients, (state, action) => {
        if (isSomething(action.payload)) {
          state.permittedElectionClients = action.payload.value;
          state.electionsEntitlementLoadStatus = DataLoadStatus.SUCCESSFUL;
        } else {
          state.permittedElectionClients = [];
          state.electionsEntitlementLoadStatus = DataLoadStatus.EMPTY_RESPONSE;
        }
      });
      builder.addCase(errElectionsEntitlements, (state, action) => {
        state.electionsEntitlementLoadStatus = action.payload;
      });
      builder.addCase(recvBankAccountsEntitlements, (state, action) => {
        if (isSomething(action.payload)) {
          state.bankAccountsEntitlements = action.payload.value;
          state.bankAccountsEntitlementsLoadStatus = DataLoadStatus.SUCCESSFUL;
        } else {
          state.bankAccountsEntitlements = {
            globalCapabilities: [],
            permittedBankAccountClients: [],
          };
          state.bankAccountsEntitlementsLoadStatus =
            DataLoadStatus.EMPTY_RESPONSE;
        }
      });
      builder.addCase(errBankAccountsEntitlements, (state, action) => {
        state.bankAccountsEntitlementsLoadStatus = action.payload;
      });
      builder.addCase(recvRestrictedEquityEntitlement, (state, action) => {
        if (isSomething(action.payload)) {
          state.hasViewRestrictedEquityEntitlement = action.payload.value;
        }
      });
      builder.addCase(errRestrictedEquityEntitlement, (state) => {
        state.hasViewRestrictedEquityEntitlement = false;
      });
      builder.addCase(recvV2DashboardEquityCardEntitlement, (state, action) => {
        if (isSomething(action.payload)) {
          state.hasViewV2DashboardEquityCardEntitlement = action.payload.value;
        }
      });
      builder.addCase(errV2DashboardEquityCardEntitlement, (state) => {
        state.hasViewV2DashboardEquityCardEntitlement = false;
      });
    }
  );
