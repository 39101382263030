import { Stack, Typography } from "@mui/material";
import {
  ColumnDefinition,
  ColumnState,
  ColumnStates,
  copyColumnStates,
  DefaultColumnDefinitions,
  DocumentColumnName,
  DocumentColumnSectionOrder,
  DocumentFilterComponents,
  DocumentGridColumnKeys,
  DocumentGridRow,
  filterDocumentTypesForSelectedDocumentTypeCategories,
  FilterPanelDocumentSections,
  Flatten,
  Optional,
  setColumnFilterValue,
  setColumnFilterValues,
} from "common";
import React, { useMemo } from "react";

import { DateRangeFilter } from "../Filters/DateRangeFilter/DateRangeFilter";
import { DocumentTypeCategoryFilter } from "../Filters/DocumentTypeCategoryFilter/DocumentTypeCategoryFilter";
import { DocumentTypeFilter } from "../Filters/DocumentTypeFilter/DocumentTypeFilter";
import { EntityFilter } from "../Filters/EntityFilter/EntityFilter";
import { PeriodFilter } from "../Filters/PeriodFilter/PeriodFilter";
import { TaxDocumentStatusFilter } from "../Filters/TaxDocumentStatusFilter/TaxDocumentStatusFilter";
import styles from "./DocumentsGridBody.module.scss";

export interface IDocumentsFilterBodyProperties {
  columnStates: ColumnStates;
  currentSelections: ColumnStates;
  onCurrentSelectionChange: (columnStates: ColumnStates) => void;
}

export const DocumentsFilterBody = ({
  columnStates,
  currentSelections,
  onCurrentSelectionChange,
}: IDocumentsFilterBodyProperties) => {
  const filteredDocumentTypeOptions = useMemo(() => {
    return filterDocumentTypesForSelectedDocumentTypeCategories(
      columnStates.documentType.filter.options,
      currentSelections.documentTypeCategory.filter.values
    );
  }, [
    columnStates.documentType.filter.options,
    currentSelections.documentTypeCategory.filter.values,
  ]);

  const DocumentFilters: DocumentFilterComponents = {
    publishedDate: (columnDefinition, columnState, onChange) =>
      DateRangeFilter({ columnDefinition, columnState, onChange }),
    effectiveDate: (columnDefinition, columnState, onChange) =>
      DateRangeFilter({ columnDefinition, columnState, onChange }),
    documentTypeCategory: (columnDefinition, columnState, onChange) =>
      DocumentTypeCategoryFilter({ columnDefinition, columnState, onChange }),
    documentType: (columnDefinition, columnState, onChange) =>
      // for document type, options should be filtered to user's selected document type category values
      DocumentTypeFilter({
        columnDefinition,
        columnState: {
          ...columnState,
          filter: {
            ...columnState.filter,
            options: filteredDocumentTypeOptions,
          },
        },
        onChange,
      }),
    funds: (columnDefinition, columnState, onChange) =>
      EntityFilter({ columnDefinition, columnState, onChange }),
    taxDocumentStatus: (columnDefinition, columnState, onChange) =>
      TaxDocumentStatusFilter({ columnDefinition, columnState, onChange }),
    period: (columnDefinition, columnState, onChange) =>
      PeriodFilter({ columnDefinition, columnState, onChange }),
    partnerships: (columnDefinition, columnState, onChange) =>
      EntityFilter({ columnDefinition, columnState, onChange }),
  };

  // typesafe retrievel of a filter that matches a column name.
  const getFilterComponent = <K extends DocumentColumnName>(
    columnDefinition: ColumnDefinition<K>,
    columnState: ColumnState<K>,
    onChange: (
      column: K,
      value:
        | Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
        | Optional<Flatten<NonNullable<DocumentGridRow[K]>>>[],
      errors: string[],
      index?: number
    ) => void
  ): JSX.Element | undefined => {
    const filter = DocumentFilters[columnDefinition.type];

    if (filter !== undefined) {
      return filter(columnDefinition, columnState, onChange);
    }
  };

  const handleChange = <
    K extends DocumentColumnName,
    T extends Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
  >(
    columnName: K,
    value: T | T[],
    errors: string[],
    index?: number
  ) => {
    const newSelections = copyColumnStates(currentSelections);

    if (index !== undefined && !Array.isArray(value)) {
      // Update value at a specific index
      setColumnFilterValue(newSelections[columnName], value, index);
    } else {
      // Update values
      const newValues = Array.isArray(value) ? value : [value];
      setColumnFilterValues(newSelections[columnName], newValues);
    }

    // Update errors
    newSelections[columnName].filter.errors = errors;

    // if there's an update to document type category values,
    if (columnName === DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY) {
      // Filter document type column's selected values in case previously selected ones got filtered out
      setColumnFilterValues(
        newSelections.documentType,
        filterDocumentTypesForSelectedDocumentTypeCategories(
          newSelections.documentType.filter.values,
          newSelections.documentTypeCategory.filter.values
        )
      );
    }

    onCurrentSelectionChange(newSelections);
  };

  return DocumentColumnSectionOrder.map((sectionHeader, sectionIndex) => {
    const sectionColumns = FilterPanelDocumentSections[sectionHeader];
    if (
      sectionColumns.some(
        (columnName) => !currentSelections[columnName].filter.disabled
      )
    ) {
      return (
        <div className={styles.sectionContainer} key={sectionIndex}>
          <Typography variant={"h4"} className={styles.sectionHeader}>
            {sectionHeader.toUpperCase()}
          </Typography>
          {sectionColumns.map((columnName) => {
            const columnDefinition = DefaultColumnDefinitions[columnName];
            const columnState = currentSelections[columnName];
            if (!columnState.filter.disabled) {
              return (
                <Stack
                  direction="column"
                  key={columnName}
                  className={styles.filterItem}
                >
                  <Typography variant={"h6"}>
                    {columnDefinition.label}
                  </Typography>
                  {getFilterComponent(
                    columnDefinition,
                    columnState,
                    handleChange
                  )}
                </Stack>
              );
            }
          })}
        </div>
      );
    }
  });
};
