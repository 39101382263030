import { createSelector } from "@reduxjs/toolkit";
import {
  BANK_ACCOUNT_PERMISSIONS,
  isSomething,
  selectActiveElection,
  selectActiveElectionClient,
} from "common";

import { InvestorUIStore } from "../store";

const selectBankAccountEntitlements = (state: InvestorUIStore) =>
  state.entitlements.bankAccountsEntitlements;
export const selectRestrictedEquityFlag = (state: InvestorUIStore) =>
  state.entitlements.hasViewRestrictedEquityEntitlement;

export const selectCanReadBankAccountsForActiveClient = createSelector(
  selectBankAccountEntitlements,
  selectActiveElectionClient,
  selectActiveElection,
  (bankAccountsEntitlements, activeElectionClient, activeElection) => {
    const { permittedBankAccountClients } = bankAccountsEntitlements;
    let hasClientPermissions = false;
    if (isSomething(activeElectionClient) && isSomething(activeElection)) {
      const client = permittedBankAccountClients.find(
        (c) => c.clientId === activeElectionClient.value.clientId
      );
      const investmentVehicle = client?.investmentVehicles.find(
        (iv) =>
          iv.investmentVehicleId === activeElection.value.investmentVehicleId
      );
      hasClientPermissions =
        !!investmentVehicle &&
        investmentVehicle.capabilities.includes(
          BANK_ACCOUNT_PERMISSIONS.READ.BANK_ACCOUNTS
        );
    }

    return hasClientPermissions;
  }
);
