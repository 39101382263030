import {
  isSomething,
  PathFragment,
  reqElectionsForClient,
  ReviewAndSignStage,
  selectActiveElectionClient,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectCanReadBankAccountsForActiveClient } from "../../../../redux/selectors/entitlementSelectors";

export const ReviewAndSignStageWrapper = () => {
  const canReadBankAccounts = useSelector(
    selectCanReadBankAccountsForActiveClient
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeElectionClient = useSelector(selectActiveElectionClient);

  const handleReturnToAllElections = () => {
    if (isSomething(activeElectionClient)) {
      dispatch(reqElectionsForClient(activeElectionClient.value));
    }
    navigate(`/${PathFragment.ELECTIONS}`);
  };

  return (
    <ReviewAndSignStage
      canReadBankAccounts={canReadBankAccounts}
      onCloseSubmitDialog={handleReturnToAllElections}
    />
  );
};
