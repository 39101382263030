import { Alert, closeAlert, getUserIdentifier, useAutoRefresh } from "common";
import React from "react";
import ReactGA from "react-ga4";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";

import { RouterProvider } from "../common/components/RouterProvider/RouterProvider";
import { Header } from "../features/Header/Header";
import { InvestorUIStore } from "../redux/store";

const App = () => {
  const auth = useAuth();
  const employeeNumber = getUserIdentifier(auth.user);
  const dispatch = useDispatch();

  if (employeeNumber !== undefined) {
    // Send the user identity to Google Analytics.
    ReactGA.set({ user_id: employeeNumber.toString() });
  }

  useAutoRefresh();

  return (
    <>
      <Header />
      <RouterProvider />
      <Alert
        alertSelector={(store: InvestorUIStore) => store.viewData.alert}
        handleClose={() => dispatch(closeAlert())}
      />
    </>
  );
};

export default App;
