import { Add } from "@mui/icons-material";
import { Button, Card, Stack, Typography } from "@mui/material";
import { ColDef, GridReadyEvent, ITooltipParams } from "ag-grid-community";
import { AgGridReact, CustomTooltipProps } from "ag-grid-react";
import {
  AddOrEditBankAccountDialog,
  BankAccountAssignment,
  BankAccountConstants,
  BankAccountTooltips,
  GPBankAccount,
  GPInvestmentVehicle,
  GPPendingBankAccount,
  hasPendingContribution,
  hasPendingDistribution,
  IBaseStore,
  isInProgress,
  isSomething,
  LoadingIndicator,
  nothing,
  obfuscateAccountNumber,
  SectionHeader,
  sortBankAccounts,
  useGridExtensions,
  UserAddedBankAccount,
  ViewOrEditBankAccount,
} from "common";
import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import styles from "./BankAccountsCard.module.scss";
import { ViewOrEditBankAccountCellRenderer } from "./ViewOrEditBankAccountCellRenderer/ViewOrEditBankAccountCellRenderer";

interface BankAccountsCardProps {
  noRowsOverlayComponent: () => JSX.Element;
}

export const BankAccountsCard = ({
  noRowsOverlayComponent,
}: BankAccountsCardProps) => {
  const gridRef = useRef<AgGridReact<ViewOrEditBankAccount>>(null);
  const { resizeColumns, onGridReady } = useGridExtensions();

  const hasPendingContributionAccount = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount = useSelector(hasPendingDistribution);

  const bankAccountsLoadStatus = useSelector(
    (state: IBaseStore) => state.bankAccounts.bankAccountsLoadStatus
  );

  const bankAccountsForClient = useSelector(
    (state: IBaseStore) => state.bankAccounts.serverData
  );

  const userAddedAccounts = useSelector(
    (state: IBaseStore) => state.bankAccounts.userAddedAccounts
  );

  const defaultColDef: ColDef<ViewOrEditBankAccount> = useMemo(
    () => ({
      tooltipValueGetter: (params: ITooltipParams<ViewOrEditBankAccount>) => {
        const { data: { requestId } = {} } = params;
        if (requestId && isSomething(requestId)) {
          return BankAccountTooltips.ACCOUNT_UNDER_REVIEW(requestId.value);
        }
      },
      tooltipComponent: (props: CustomTooltipProps) => {
        return <Card className={styles.rowTooltip}>{props.value}</Card>;
      },
      resizable: true,
      filter: false,
      minWidth: 110,
      menuTabs: [],
      sortable: false,
      lockPinned: true,
      suppressHeaderMenuButton: true,
    }),
    []
  );

  const investmentVehicleIds = useMemo(() => {
    return bankAccountsForClient.investmentVehicles.map(
      (investmentVehicle: GPInvestmentVehicle) => investmentVehicle.axiomId
    );
  }, [bankAccountsForClient]);

  type SafeColDef<T> = Omit<ColDef<T>, "field"> & { field?: keyof T };
  const colDefs: SafeColDef<ViewOrEditBankAccount>[] = useMemo(() => {
    return [
      {
        field: "accountHolderName",
        headerName: BankAccountConstants.ACCOUNT_HOLDER,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
      {
        field: "bankName",
        headerName: BankAccountConstants.BANK_NAME,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
      {
        field: "accountNumber",
        headerName: BankAccountConstants.ACCOUNT_ENDING_IN,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return obfuscateAccountNumber(params.value);
        },
      },
      {
        field: "accountType",
        headerName: BankAccountConstants.TYPE,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
      {
        field: "isUnderReview",
        headerName: "",
        maxWidth: 60,
        width: 60,
        tooltipValueGetter: () => null, // remove tooltip for this column
        cellRenderer: (params: {
          value: string;
          data: ViewOrEditBankAccount;
        }) => {
          if (!params.data) return;

          return (
            <ViewOrEditBankAccountCellRenderer
              account={params.data}
              investmentVehicleIds={investmentVehicleIds}
            />
          );
        },
      },
    ];
  }, [investmentVehicleIds]);

  const rowData: ViewOrEditBankAccount[] = useMemo(() => {
    const bankAccounts: ViewOrEditBankAccount[] = [];
    if (bankAccountsForClient) {
      if (bankAccountsForClient.bankAccounts.length > 0) {
        bankAccounts.push(
          ...bankAccountsForClient.bankAccounts.map((item: GPBankAccount) => {
            return {
              ...item.bankAccount.main,
              isUnderReview: false,
              requestId: nothing,
            };
          })
        );
      }
      if (bankAccountsForClient.pendingBankAccounts.length > 0) {
        bankAccounts.push(
          ...bankAccountsForClient.pendingBankAccounts.map(
            (item: GPPendingBankAccount) => {
              return {
                ...item.main,
                isUnderReview: true,
                requestId: item.requestId,
              };
            }
          )
        );
      }
    }
    if (userAddedAccounts.length > 0) {
      bankAccounts.push(
        ...userAddedAccounts.map((acc: UserAddedBankAccount) => {
          return {
            ...acc.main,
            isUnderReview: false,
            requestId: nothing,
          };
        })
      );
    }
    return sortBankAccounts(bankAccounts);
  }, [bankAccountsForClient, userAddedAccounts]);

  const [isAddBankAccountDialogOpen, setIsAddBankAccountDialogOpen] =
    useState<boolean>(false);

  return (
    <Stack className={styles.cardContainer}>
      <div className={styles.cardHeader}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography variant="h1">
            {SectionHeader.BANK_ACCOUNTS_TITLE}
          </Typography>
          <Button
            className={styles.addNewButton}
            endIcon={<Add />}
            onClick={() => setIsAddBankAccountDialogOpen(true)}
            disabled={
              investmentVehicleIds.every(
                (iv) => hasPendingContributionAccount[iv]
              ) &&
              investmentVehicleIds.every(
                (iv) => hasPendingDistributionAccount[iv]
              )
            }
          >
            {SectionHeader.NEW_BANK_ACCOUNT}
          </Button>
        </Stack>
        <Typography variant="body1">
          {SectionHeader.BANK_ACCOUNTS_DESCRIPTION}
        </Typography>
      </div>
      <AddOrEditBankAccountDialog
        open={isAddBankAccountDialogOpen}
        setOpen={setIsAddBankAccountDialogOpen}
        showAutoAssignSection={investmentVehicleIds.length === 1}
        assignmentType={BankAccountAssignment._}
        investmentVehicleIds={investmentVehicleIds}
      />
      <Stack className="ag-theme-alpine" id={styles.dataGrid}>
        <AgGridReact<ViewOrEditBankAccount>
          loading={isInProgress(bankAccountsLoadStatus)}
          ref={gridRef}
          rowData={rowData}
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onModelUpdated={resizeColumns}
          suppressMenuHide={true}
          enableCellTextSelection={true}
          onGridReady={(params: GridReadyEvent) => {
            onGridReady(params);
          }}
          noRowsOverlayComponent={noRowsOverlayComponent}
          scrollbarWidth={10}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          tooltipShowDelay={0}
          loadingOverlayComponent={LoadingIndicator}
        />
      </Stack>
    </Stack>
  );
};
