import {
  EntityColumns,
  EntitySelector,
  Flatten,
  IEntity,
  isSomething,
  Optional,
} from "common";
import React, { useCallback, useEffect, useState } from "react";

import {
  FilterComponentProps,
  mapOptionalToEntity,
} from "../shared/FilterComponentProps";

export const EntityFilter = <K extends EntityColumns>(
  props: FilterComponentProps<K, IEntity[]>
) => {
  const mapColumnToEntity = useCallback(
    () =>
      mapOptionalToEntity(
        props.columnState.filter.values.filter(
          (value) =>
            isSomething(value) &&
            props.columnState.filter.options.find(
              (option) =>
                isSomething(option) &&
                option.value.name === value.value.name &&
                option.value.oId === value.value.oId
            )
        )
      ),
    [props.columnState.filter.options, props.columnState.filter.values]
  );
  const [selections, setSelections] = useState<IEntity[]>(mapColumnToEntity());

  useEffect(() => {
    setSelections(mapColumnToEntity);
  }, [mapColumnToEntity]);

  const onChange = (newValuesSelected: IEntity[]) => {
    const newValuesNames = newValuesSelected.map((val: IEntity) => val.name);

    const newValues = props.columnState.filter.options.filter(
      (option: Optional<IEntity>) =>
        isSomething(option) && newValuesNames.includes(option.value.name)
    );

    props.onChange(
      props.columnDefinition.type,
      newValues as Optional<NonNullable<Flatten<IEntity[]>>>[],
      []
    );

    setSelections(newValuesSelected);
  };

  const options = mapOptionalToEntity(props.columnState.filter.options);

  return (
    <EntitySelector
      options={options}
      selection={selections}
      onChange={onChange}
    />
  );
};
