import {
  AuthenticatedPage,
  EnvironmentResolver,
  IBaseStore,
  IInvestmentVehicleByClientByPeriod,
  isInProgress,
  isNotRequested,
  isSomething,
  LoadingIndicator,
  NoPermissionPage,
  nothing,
  Optional,
  PeriodHash,
  RelativePath,
  reqInternalInvestmentData,
  reqUserAgreements,
  selectCanViewCommitmentsPage,
  selectCanViewDashboard,
  selectCanViewInvestmentsAndCarry,
  some,
  useFetchDatasetIfIdDefined,
  useFetchEntitlements,
} from "common";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { PageName, Pages } from "../../../constants/Pages/Pages";
import { Login } from "../../../features/Authentication/Login";
import { InvestorUIStore } from "../../../redux/store";
import {
  canViewBankAccountPage,
  isPageVisible,
} from "../../utils/routingUtils";
import { UIErrorBoundary } from "../UIErrorBoundary/UIErrorBoundary";
import styles from "./RouterProvider.module.scss";

export const RouterProvider = () => {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const { userAgreements, userAgreementsLoadStatus } = useSelector(
    (state: InvestorUIStore) => state.userAgreementData
  );

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
      isNotRequested(userAgreementsLoadStatus)
    ) {
      dispatch(reqUserAgreements());
    }
  }, [auth.isAuthenticated, dispatch, userAgreementsLoadStatus]);

  const showUserAgreements: boolean =
    EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG &&
    userAgreements.filter(
      (agreement) =>
        (agreement.isMandatory && agreement.hasAgreed !== true) ||
        agreement.hasAgreed === null
    ).length > 0;

  //Get the value of activeInternalInvestmentDataClientId, activeEquityEntitlement, isViewEquityEntitlementLoaded and isViewInvestorEntitlementsLoaded
  const {
    viewEquityEntitlements,
    hasViewDocumentsEntitlement,
    bankAccountsEntitlements,
    bankAccountsEntitlementsLoadStatus,
    equityDataEntitlementLoadStatus,
    internalInvestmentEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    permittedElectionClients,
  } = useSelector((state: InvestorUIStore) => state.entitlements);

  const pageLoadStatus = useSelector(
    (state: InvestorUIStore) => state.viewData.pageLoadStatus
  );

  const internalInvestmentData = useSelector(
    (state: InvestorUIStore) => state.internalInvestmentData
  );

  const canViewDashboard = useSelector((store: IBaseStore) =>
    selectCanViewDashboard(store, true)
  );

  const canViewInvestmentsAndCarryPage = useSelector((store: IBaseStore) =>
    selectCanViewInvestmentsAndCarry(store, true)
  );

  const canViewCommitmentsPage = useSelector(selectCanViewCommitmentsPage);

  useFetchEntitlements(auth.user, [
    internalInvestmentEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    bankAccountsEntitlementsLoadStatus,
  ]);

  const [dashboardInvestmentDataFilter, setDashboardInvestmentDataFilter] =
    useState<Optional<IInvestmentVehicleByClientByPeriod>>(nothing);

  useFetchDatasetIfIdDefined(
    reqInternalInvestmentData,
    dashboardInvestmentDataFilter,
    internalInvestmentData.internalInvestmentDataLoadStatus
  );

  useEffect(() => {
    if (isSomething(internalInvestmentData.filteredBy)) {
      setDashboardInvestmentDataFilter(
        some({
          clientId: internalInvestmentData.filteredBy.value.clientId,
          periodId: PeriodHash.LATEST,
          investmentVehicleId: undefined,
        })
      );
    } else {
      setDashboardInvestmentDataFilter(nothing);
    }
  }, [internalInvestmentData.filteredBy]);

  /*
  Set Dashboard Page if user have internal investment data entitlements.
  Set Equity Page if user have equity data entitlements.
  Set NoPermission Page if user have neither. 
  */

  const permissionsToCheck = [
    internalInvestmentEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    bankAccountsEntitlementsLoadStatus,
  ];

  if (EnvironmentResolver.ENV.SHOW_USER_AGREEMENTS_FEATURE_FLAG === true) {
    permissionsToCheck.push(userAgreementsLoadStatus);
  }

  const SetRouteByEntitlements = () => {
    if (isInProgress(...permissionsToCheck)) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }
    // if we have a default client, we want to check presence of data before displaying pages
    if (
      isSomething(internalInvestmentData.filteredBy) &&
      isInProgress(internalInvestmentData.internalInvestmentDataLoadStatus)
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }
    const urlPath = location.pathname;
    const urlPathLower = urlPath.toLowerCase();
    if (
      urlPath !== urlPathLower &&
      Object.keys(Pages)
        .map((object) => Pages[object as PageName].path)
        .some((item) => item === urlPathLower)
    ) {
      /*
      if url string contains uppercase and corresponds to a valid page when made lowercase, navigate to the lowercase route.
        This forces all urls to automatically become lowercase, even if typed in capitals
      */
      return <Navigate to={urlPathLower} />;
    }
    // IMPORTANT: the order here determines which page user gets routed to by default!!
    return showUserAgreements ? (
      <Navigate to={Pages.UserAgreements.path} />
    ) : canViewDashboard ? (
      <Navigate to={Pages.Dashboard.path} />
    ) : canViewInvestmentsAndCarryPage ? (
      <Navigate to={Pages.InvestmentsandCarry.path} />
    ) : canViewCommitmentsPage ? (
      <Navigate to={Pages.Commitments.path} />
    ) : permittedElectionClients.length > 0 ? (
      <Navigate to={Pages.ElectionsList.path} />
    ) : viewEquityEntitlements.length > 0 ? (
      <Navigate to={Pages.Equity.path} />
    ) : hasViewDocumentsEntitlement ? (
      <Navigate to={Pages.Documents.path} />
    ) : canViewBankAccountPage(bankAccountsEntitlements) ? (
      <Navigate to={Pages.BankAccounts.path} />
    ) : (
      <NoPermissionPage />
    );
  };

  return (
    <div className={styles.page}>
      <Routes>
        <Route path={RelativePath.LOGIN} element={<Login />} />
        {Object.keys(Pages).map((pageKey) => {
          if (
            isPageVisible(
              pageKey as PageName,
              showUserAgreements,
              canViewDashboard,
              canViewInvestmentsAndCarryPage,
              viewEquityEntitlements,
              hasViewDocumentsEntitlement,
              canViewCommitmentsPage,
              permittedElectionClients,
              bankAccountsEntitlements
            )
          ) {
            const page = Pages[pageKey as PageName];
            return (
              <Route
                key={page.path}
                path={page.path}
                caseSensitive
                element={
                  <UIErrorBoundary page={page}>
                    <AuthenticatedPage
                      pageElement={page.element}
                      pageLoadStatus={pageLoadStatus}
                      hideFooter={page.name === Pages.UserAgreements.name}
                    />
                  </UIErrorBoundary>
                }
              />
            );
          }
        })}
        <Route path={"*"} element={SetRouteByEntitlements()} />
      </Routes>
    </div>
  );
};
