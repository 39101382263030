import { IBankAccountsEntitlements, IPermittedClient } from "common";

import { PageName } from "../../constants/Pages/Pages";

export const canViewBankAccountPage = (
  bankAccountsEntitlements: IBankAccountsEntitlements
) => {
  return (
    bankAccountsEntitlements.permittedBankAccountClients.flatMap(
      (client) => client.investmentVehicles
    ).length >= 1
  );
};

export const isPageVisible = (
  pageName: PageName,
  showUserAgreements: boolean,
  canViewDashboardPage: boolean,
  canViewInvestmentsAndCarryPage: boolean,
  activeEquityDataEntitlements: string[],
  hasViewDocumentsEntitlement: boolean,
  canViewCommitmentsPage: boolean,
  permittedElectionsClients: IPermittedClient[],
  bankAccountsEntitlements: IBankAccountsEntitlements
) => {
  const pageVisibility = {
    [PageName.USER_AGREEMENTS]: () => showUserAgreements,
    [PageName.BANK_ACCOUNTS]: () =>
      canViewBankAccountPage(bankAccountsEntitlements),
    [PageName.INVESTMENTS_AND_CARRY]: () => canViewInvestmentsAndCarryPage,
    [PageName.DASHBOARD]: () => canViewDashboardPage,
    [PageName.COMMITMENTS]: () => canViewCommitmentsPage,
    [PageName.EQUITY]: () => activeEquityDataEntitlements.length > 0,
    [PageName.DOCUMENTS]: () => hasViewDocumentsEntitlement,
    [PageName.ELECTION_WORKFLOW]: () => permittedElectionsClients.length > 0,
    [PageName.ELECTIONS_LIST]: () => permittedElectionsClients.length > 0,
    [PageName.REVIEW]: () => permittedElectionsClients.length > 0,
    [PageName.TERMS_OF_USE]: () => true,
  };

  if (showUserAgreements) {
    return pageName === PageName.USER_AGREEMENTS;
  }

  return pageVisibility[pageName]();
};
