import { ElectionWorkflowPage, NoDataAvailableError } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { ElectionWorkflowPages } from "../../../constants/Pages/ElectionWorkflowPages";
import { selectCanReadBankAccountsForActiveClient } from "../../../redux/selectors/entitlementSelectors";
import { InvestorUIStore } from "../../../redux/store";
import { useActiveElection } from "../Hooks/useActiveElectionHook";
import { useElectionQueryParams } from "../Hooks/useElectionQueryParamsHook";
import styles from "./ElectionWorkflowPageWrapper.module.scss";

export const ElectionWorkflowPageWrapper = () => {
  const { investmentVehicleId, electionRoundId } = useElectionQueryParams();
  useActiveElection();

  const { permittedElectionClients } = useSelector(
    (state: InvestorUIStore) => state.entitlements
  );

  const canReadBankAccounts = useSelector(
    selectCanReadBankAccountsForActiveClient
  );

  if (Object.keys(permittedElectionClients).length === 0) {
    return (
      <div className={styles.status}>
        <NoDataAvailableError />
      </div>
    );
  }

  return (
    <ElectionWorkflowPage
      canReadBankAccounts={canReadBankAccounts}
      ElectionWorkflowPages={ElectionWorkflowPages}
      electionListPath={`/sbs-elections`}
      electionDetailPath={`/sbs-elections/${investmentVehicleId}/${electionRoundId}`}
      isAdmin={false}
    />
  );
};
