import { Link, Typography } from "@mui/material";
import React from "react";

export const CookieNotificationConstants = {
  ACCEPT_BUTTON_TEXT: "Continue With Cookies",
  DECLINE_BUTTON_TEXT: "Continue Without Cookies",
  DISCLAIMER: (
    <Typography>
      This website uses optional first-party and third-party cookies and
      tracking technologies to enhance functionality and personalize your
      experience. Please{" "}
      <Link
        href="https://www.blackstone.com/privacy/cookie-notice/"
        target="_blank"
      >
        click here
      </Link>{" "}
      to find out more about cookies. These cookies will only be set if you
      accept them.
    </Typography>
  ),
  TITLE: "Cookie Information and Consent Request",
};
