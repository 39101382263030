import { Dashboard, EnvironmentResolver } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../redux/store";

const DashboardWrapper = () => {
  const entitlements = useSelector(
    (store: InvestorUIStore) => store.entitlements
  );

  return (
    <Dashboard
      hasViewStockEntitlement={entitlements.hasViewStockEntitlement}
      hasViewRestrictedEquity={
        EnvironmentResolver.ENV.RESTRICTED_EQUITY_FEATURE_FLAG ||
        entitlements.hasViewRestrictedEquityEntitlement
      }
      hasViewV2DashboardEquityCardEntitlement={
        entitlements.hasViewV2DashboardEquityCardEntitlement
      }
    />
  );
};

export default DashboardWrapper;
