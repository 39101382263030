import {
  FailedToLoadError,
  IBaseStore,
  isSomething,
  isUnsuccessful,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  reqBankAccountsForClient,
  setActiveBankAccountClient,
  some,
  useFetchDatasetIfIdDefined,
  useFetchDatasetWithoutId,
} from "common";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../redux/store";
import { AssignBankAccountsCard } from "./AssignBankAccountsCard/AssignBankAccountsCard";
import styles from "./BankAccounts.module.scss";
import { BankAccountsCard } from "./BankAccountsCard/BankAccountsCard";
import { NoBankAccountsMessage } from "./NoBankAccountsMessage/NoBankAccountsMessage";

export const BankAccounts = () => {
  const dispatch = useDispatch();

  const {
    activeBankAccountsClient,
    bankAccountsLoadStatus,
    countriesLoadStatus,
    currenciesLoadStatus,
  } = useSelector((state: IBaseStore) => state.bankAccounts);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqBankAccountsForClient,
    activeBankAccountsClient,
    bankAccountsLoadStatus
  );

  useFetchDatasetWithoutId(reqBankAccountCountries, countriesLoadStatus);
  useFetchDatasetWithoutId(reqBankAccountCurrencies, currenciesLoadStatus);

  const overlayComponent = useMemo(() => {
    return isUnsuccessful(bankAccountsLoadStatus)
      ? () => <FailedToLoadError retryRequest={retryFunction} />
      : NoBankAccountsMessage;
  }, [bankAccountsLoadStatus, retryFunction]);

  const {
    bankAccountsEntitlements: { permittedBankAccountClients },
  } = useSelector((store: InvestorUIStore) => store.entitlements);

  useEffect(() => {
    if (!isSomething(activeBankAccountsClient) && permittedBankAccountClients) {
      dispatch(
        setActiveBankAccountClient(some(permittedBankAccountClients[0]))
      );
      dispatch(reqBankAccountsForClient(permittedBankAccountClients[0]));
    }
  });

  return (
    <div className={styles.pageContent}>
      <AssignBankAccountsCard retryFunction={retryFunction} />
      <BankAccountsCard noRowsOverlayComponent={overlayComponent} />
    </div>
  );
};
