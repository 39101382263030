import { Button, Tooltip } from "@mui/material";
import {
  colors,
  ElectionStatus,
  ElectionStatusName,
  ElectionStatusTitle,
  ElectionWorkflowStageId,
  IElectionsForClientListItem,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./ElectionChip.module.scss";

const getChartCellColorByStatus = (status: ElectionStatus): string => {
  switch (status) {
    case ElectionStatus.READY_TO_START:
    case ElectionStatus.IN_PROGRESS:
      return colors.avocado;
    case ElectionStatus.READY_FOR_REVIEW:
    case ElectionStatus.SUBMITTED:
      return colors.cobalt;
  }
  return colors.silver;
};

const isInProgess = (stage: ElectionWorkflowStageId): boolean => {
  return (
    stage === ElectionWorkflowStageId.OVERVIEW ||
    stage === ElectionWorkflowStageId.ELIGIBILITY ||
    stage === ElectionWorkflowStageId.STRATEGIES ||
    stage === ElectionWorkflowStageId.ELECT ||
    stage === ElectionWorkflowStageId.BANK_ACCOUNT ||
    stage === ElectionWorkflowStageId.REVIEW_AND_SIGN
  );
};

const getStatusByStage = (
  stage: ElectionWorkflowStageId,
  submissionDeadline: Date,
  systemOpenDate: Date,
  systemClosedDate: Date
): ElectionStatus => {
  const now = new Date();
  if (submissionDeadline < now && stage === ElectionWorkflowStageId.COMPLETED) {
    return ElectionStatus.SUBMITTED;
    // show election as closed if close date has passed,
    // but also if election deadline has passed and user hasn't started
  } else if (
    systemOpenDate > now ||
    systemClosedDate < now ||
    (submissionDeadline < now &&
      (stage === ElectionWorkflowStageId.NOT_STARTED ||
        stage === ElectionWorkflowStageId.UNKNOWN))
  ) {
    return ElectionStatus.CLOSED;
  }
  if (
    stage === ElectionWorkflowStageId.NOT_STARTED ||
    stage === ElectionWorkflowStageId.UNKNOWN
  ) {
    return ElectionStatus.READY_TO_START;
  }
  if (isInProgess(stage)) {
    return ElectionStatus.IN_PROGRESS;
  }
  if (stage === ElectionWorkflowStageId.COMPLETED) {
    return ElectionStatus.READY_FOR_REVIEW;
  }
  return ElectionStatus.CLOSED;
};

export const ElectionsChip = (props: IElectionsForClientListItem) => {
  const navigate = useNavigate();
  const currentElection = props;
  const currentDate = new Date();
  const [isDisabled, setIsDisabled] = useState(
    currentElection.systemCloseDate < currentDate
  );

  useEffect(() => {
    if (currentElection) {
      const isWithinSystemDates =
        currentElection.systemCloseDate >= currentDate &&
        currentElection.systemOpenDate <= currentDate;
      const isWithinSubmissionDeadline =
        currentElection.electionSubmissionDeadline >= currentDate;
      const currentStage = currentElection.currentStage;
      // disable election if not started and passed the submit deadline
      if (
        !isWithinSubmissionDeadline &&
        (currentStage === ElectionWorkflowStageId.NOT_STARTED ||
          currentStage === ElectionWorkflowStageId.UNKNOWN)
      ) {
        setIsDisabled(true);
        // disable election if not in completed status and not with system open/close dates
      } else if (
        !isWithinSystemDates &&
        currentElection.currentStage !== ElectionWorkflowStageId.COMPLETED
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentElection]);

  const handleButtonClick = () => {
    if (
      currentElection.currentStage === ElectionWorkflowStageId.NOT_STARTED ||
      currentElection.currentStage === ElectionWorkflowStageId.UNKNOWN ||
      isInProgess(currentElection.currentStage)
    )
      return navigate(
        `/sbs-elections/${currentElection.investmentVehicle.investmentVehicleId}/${currentElection.electionRoundId}`
      );
    return navigate(
      `/sbs-elections/${currentElection.investmentVehicle.investmentVehicleId}/${currentElection.electionRoundId}/review`
    );
  };

  const electionStatus: ElectionStatus = useMemo(() => {
    return getStatusByStage(
      currentElection.currentStage,
      currentElection.electionSubmissionDeadline,
      currentElection.systemOpenDate,
      currentElection.systemCloseDate
    );
  }, [currentElection]);

  return (
    <Tooltip
      title={ElectionStatusTitle[electionStatus]}
      placement="bottom-start"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [75, -5],
            },
          },
        ],
      }}
      disableTouchListener={true}
    >
      <span>
        <Button
          className={styles.chip}
          style={{
            backgroundColor: getChartCellColorByStatus(electionStatus),
          }}
          disabled={isDisabled}
          onClick={handleButtonClick}
        >
          {ElectionStatusName[electionStatus]}
        </Button>
      </span>
    </Tooltip>
  );
};
